const group = [
  { name: "Our Vision", to: "/vision" },
  {
    name: "Locations",
    dropDown: [
      { name: "Barnkids", ext: "https://www.barnkids.co.uk"},
      { name: "Fairlands", ext: "https://www.fairlandsnursery.co.uk"},
    ],
  },
  { name: "News", to: '/blog'},
  { name: "Careers", to: "/careers" },
  { name: "01428 685633", ext:"tel:01428685633"}
]

const fairlands = [
  {name: "Parent Information", 
  dropDown: [
    {name:"Team", to: "/team"},
    {name:"Fees", to:"/fees"},
    {name:"Food", to:"/food"},
    {name:"Polices", to:"/policies"}
  ]},
  {name:"Careers", to:"/careers"},


  {name:"Register", to:"/register"}
]

export {group, fairlands}