import React from "react"
import {Header, Footer, CookieBanner} from "@barnkids/barnkids-website-shared"
import { createUseStyles } from "react-jss"
import { Helmet } from "react-helmet"
import { group as menu} from "./menu"
import {graphql, useStaticQuery} from 'gatsby'

const useStyles = createUseStyles({
  "@global": {
    body: {
      margin: 0,
      fontSize: 18,
      fontFamily: [
        "effra",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ],
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
    },
    p: {
      color:'rgba(100 ,100,100,1)',
    },
    h1: { 
      fontSize: "2rem",
      fontFamily: [
        "Fertigo-pro"
      ]
    },
    h2: { 
      fontSize: "1.25rem",
      paddingTop:30,
      fontFamily: [
        "Fertigo-pro"
      ]
    },
    
  },
  content: {
    marginTop:80,
  }
})

const query = graphql`
{
  site: sanitySiteInfo(SiteName: {eq: "Barnkids Group"}) {
    SiteName
    _rawDescription (resolveReferences: {maxDepth: 5})
    _rawAddress (resolveReferences: {maxDepth:5})
    TelNumber
    email
    facebook
    instagram
    map
    OfstedURN
  }
}`

const Layout = ({ children,  pageContext, data, location, ...props }) => {
  if (location.pathname.includes('learning')){ 
    pageContext= {site:'both'}
  }
  const staticQuery = useStaticQuery(query)
  const classes = useStyles()
  const newProps = {pageContext, location, ...props}
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Barnkids Group is a small chain of family run nurseries in Surrey and Hampshire"
        />
        <meta name="title" content="Barnkids Nurseries" />
      </Helmet>
      <Header menu={menu} siteData={staticQuery.site} {...newProps} /> 
      <div className={classes.content} >{children}</div>
      
     <CookieBanner/>
      <Footer menu={menu} siteData={staticQuery.site} {...newProps} />
    </React.Fragment>
  )
}

export default Layout
